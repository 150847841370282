import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SiteHeader from '../components/SiteHeader';

const NotFoundPage = () => (
  <Layout>
    <SiteHeader />
    <Container>
      <h1>Sorry, there's nothing here...</h1>
      <p>
        Check in the footer to see if the link you are looking for exists, or <Link to="/">head back to our home page.</Link>
      </p>
    </Container>
  </Layout>
)
export default NotFoundPage